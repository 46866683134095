/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */
/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";
/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';
/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";
ion-content {
  --ion-background-color: #e1eef5;
}

ion-modal {
  --max-width: 350px;
  --max-height: 460px;
}

ion-card {
  background-color: #fff;
}

ion-item {
  --background: #fff;
}

ion-label {
  white-space: normal !important;
}

.hidden-element {
  display: none !important;
}

.global-page-container {
  margin: 0 auto;
  max-width: 800px;
  padding: 0 10px;
}

.item-border-radius {
  --border-radius: 30px;
  --background: #fff;
}

.item-border-radius-top {
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
  --background: #fff;
}

.item-border-radius-bottom {
  border-bottom-left-radius: 30px;
  border-bottom-right-radius: 30px;
  --background: #fff;
}

.item-no-padding {
  --inner-padding-start: 0;
  --inner-padding-end: 0;
  --padding-start: 0;
  --padding-end: 0;
}

.alert-checkbox-label {
  white-space: normal !important;
}

.alert-radio-label {
  white-space: normal !important;
}

.alert-checkbox-button {
  height: auto !important;
  contain: content !important;
}

.alert-radio-button {
  height: auto !important;
  contain: content !important;
}

.ripple-parent {
  position: relative;
  overflow: hidden;
}

.uppercase input {
  text-transform: uppercase !important;
}

.div_logo_associazione_header {
  position: absolute;
  top: 20px;
  right: 15px;
  width: 40px;
  height: 40px;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
}

h1.title {
  border-radius: 5px;
  background-color: var(--ion-color-primary);
  color: #fff;
  padding: 3px 5px;
  font-size: 20px;
  text-align: center;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
}

h1.title2 {
  font-weight: bold;
  font-size: 20px;
  color: var(--ion-color-primary);
  border-bottom: 1px solid var(--ion-color-primary);
}

ion-modal {
  --max-width: 400px;
}

[hidden] {
  display: none !important;
}